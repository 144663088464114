<template>
  <!-- index.html -->
  <div class="home  home_mobile">

    <section id="section-1" class="section-content no-padding">
      <div class="head-row-old">
        <img src="../../../assets/gb/GBWhatsApp.png" class="logo " />
        <div class="app-intro">
          <h1 class="section-1-rule-old" v-html="$global.formatstr(doc.s1.h1_0712, month)"></h1>
          <div class="recommend-row">
            <div class="recommend-item">
              <img src="../../../assets/gb/securit.png" alt="security" />
              Security verified
            </div>
            <div class="recommend-item">
              <img src="../../../assets/gb/certificati@2x.png" alt="certification" />
              Official certification
            </div>
          </div>
        </div>
      </div>

      <DownloadBtn class="fix has-exp" v-show="showFixBtn"
        :exp-data="`show_${pageName}home_download_fix`" :name="pageName" :from="from"
        :filename="filename" :isOffical="isOffical" />

      <ApkDetail :doc="doc" />

      <DownloadBtn class="top-download has-exp" :exp-data="`show_${pageName}home_download`"
        :name="pageName" :from="from" :filename="filename" :isOffical="isOffical" />

      <h2 class="intro-title intro-title-1">
        GB WhatsApp APK Download 2024 New Version
      </h2>

      <lazysource :source="require('@/assets/gb/New_banner.webp')"
        :img="require('@/assets/gb/New_banner.webp')" classname="writer-banner"></lazysource>

      <p class="intro-item intro-item-2">
        Recent years, WhatsApp has become a popular platform for people sharing their life. With the
        gradual increase in the number of users, many special needs also arise. If you're looking
        for additional features for your communication needs, you can consider downloading the
        latest version of GB WhatsApp in 2024.
        <br><br>
        Compared to the official WhatsApp, GBWhatsApp Apk offers a range of unique features. These
        include call blocking, unlimited themes, the ability to hide online status, and more. In
        this article, we will discuss all of these features in detail.
      </p>

    </section>

    <S2 :doc="doc" />

    <div id="snippet-box">
      <div class="snippet-title">Summary</div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">Author Rating</div>
          <div class="snippet-data-img"><span class="star-img"><img src="@/assets/1star.png"
                alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span></div>
          <div class="snippet-clear"></div>
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">Aggregate Rating</div>
            <div class="snippet-data-img"><span itemprop="ratingValue">3.65</span> based on <span
                class="rating-count" itemprop="ratingCount">46258</span> votes </div>
          </div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Software Name</div>
          <div class="snippet-data-img"> <span itemprop="name">GBWhatsApp APK</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Operating System</div>
          <div class="snippet-data-img"> <span itemprop="operatingSystem">Android</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Software Category</div>
          <div class="snippet-data-img"> <span itemprop="applicationCategory">App</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Price</div>
          <div class="snippet-data-img"> <span itemprop="offers" itemscope=""
              itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Landing Page</div>
          <div class="snippet-data-img"> <a itemprop="featureList"
            href="https://gbapksdownload.com/ ">https://gbapksdownload.com/</a></div>
            <div class="snippet-clear"></div>
        </div>
      </div>
    </div>

    <Rate />

  </div>
</template>

<script>
import "@/css/gb/mobile.scss";
import DownloadBtn from "@/components/DownloadBtnNew.vue";
import S2 from "../assets/section2-0712.vue";
import ApkDetail from "../assets/apkdetail.vue";
import lazysource from "@/components/lazysource.vue";
import Rate from "@/components/rate.vue";


export default {
  name: "mobile",
  components: {
    DownloadBtn,
    S2,
    ApkDetail,
    Rate,
    lazysource,
  },
  data () {
    return {
      pageName: "gb",
      doc: null,
      myApp: null,
      from: "gb",
      filename: "gb",
      showFixBtn: true,



      month: "",

      showTeleMsg: true,
      showTopPic: false,

      isOffical: 0,
      ratingStar: null,
      showCollect: false,
    };
  },
  created () {
    this.myApp = this.$store.state.gb;
    this.doc = this.myApp.doc;
  },
  beforeMount () {
    [this.from, this.filename, this.isOffical] = [
      this.$global.GetQueryString("from") || "",
      this.$global.GetQueryString("filename") || "",
      parseInt(this.$global.GetQueryString("isOffical")) || 0,
    ];
    console.log(`from:${this.from},filename:${this.filename}`);

    this.$store.state.activeName = this.myApp.name;
    this.$store.state.activeLogo = this.myApp.logo;

    const monthArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.month = monthArray[new Date().getMonth()];

    console.log(`show_gbhome_${this.$route.name}`);

    this.$logEvent(`show_gbhome_${this.$route.name}`);
  },
  mounted () {
    console.log("mounted");


    setTimeout(() => {
      this.viewHeight = window.innerHeight;
      this.scrollEvent();
      // 监听scroll事件
      this.scrollEventFun = this.$global.throttle(this.scrollEvent, 300);
      window.addEventListener("scroll", this.scrollEventFun, false);

      if (this.$route.params.scrollTo) {
        document
          .querySelector(`#${this.$route.params.scrollTo}`)
          .scrollIntoView({
            behavior: "smooth",
          });
      }
    }, 1000);
  },
  deactivated () {
    window.removeEventListener("scroll", this.scrollEventFun, false);
  },
  beforeDestroy () {
    // 防止内存溢出
    window.removeEventListener("scroll", this.scrollEventFun, false);
  },
  methods: {
    getOffsetTop (element) {
      let actualTop = element.offsetTop;
      let current = element.offsetParent;
      while (current !== null) {
        actualTop += current.offsetTop;
        current = current.offsetParent;
      }
      return actualTop;
    },
    // 获取窗口滚动高度
    scrollTop () {
      return (
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset
      );
    },
    scrollEvent () {
      let $scrollTop = this.scrollTop();
      if ($scrollTop >= 200) {
        document.getElementById("head").classList.add("fix_top_gb");
      }
      if ($scrollTop >= 20) {
        this.showTeleMsg = false;
      }
      console.log(this.getOffsetTop(document.getElementById("footer")));
      // console.log($scrollTop);

      if ($scrollTop < 200) {
        document.getElementById("head").classList.remove("fix_top_gb");
      }
      let nodeArr = document.querySelectorAll(".has-exp");
      for (let i = 0; i < nodeArr.length; i++) {
        let domTop =
          this.getOffsetTop(nodeArr[i]) +
          nodeArr[i].getBoundingClientRect().height / 3;
        // 当正文部分露出即发此曝光
        let topHeight = this.getOffsetTop(nodeArr[i]) - $scrollTop;
        // console.log('topHeight', topHeight);
        let bottomHeight = domTop - $scrollTop;
        if (topHeight >= 0 && bottomHeight <= this.viewHeight) {
          if (!nodeArr[i].getAttribute("data-exposure")) {
            nodeArr[i].setAttribute("data-exposure", "1");
            const _this = this;
            new Promise((resolve) => {
              resolve();
            }).then(() => {
              let expData = nodeArr[i].getAttribute("exp-data");
              _this.expPost(expData);
            });
          }
        }
        if (topHeight > this.viewHeight) return false;
      }
    },
    expPost (exposeData) {
      this.$logEvent(exposeData);
      console.log(`曝光统计log ${exposeData}`);
    },

  },
  metaInfo () {
    return {
      title: `GBWhatsApp APK Download  (Anti-Ban) ${this.$root.month} 2024 (Virus Free) | OFFICIAL`,
      script: [
        {
          vmid: "extscript",
          innerHTML: `
					{
						"@context": "https://schema.org",
						"@graph": [
							{
								"@type": "BreadcrumbList",
								"itemListElement": [
									{ "@type": "ListItem", "position": 1, "name": "GBWhatsApp", "item": "https://gbapksdownload.com/" },
									{ "@type": "ListItem", "position": 2, "name": "GB WhatsApp apk download" , "item": "https://gbapksdownload.com/gbwhatsapp-apk/" },
									{ "@type": "ListItem", "position": 3, "name": "GBWhatsApp Download 2024"  }
								]
							}
							
						]
					}
				`,
          type: "application/ld+json",
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
      meta: [
        {
          name: "keywords",
          vmid: "keywords",
          content:
            "gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download",
        },
        {
          name: "image",
          vmid: "image",
          content: `https://cdn.gbwhatsapp.download/img/icon_GB_new@2x.3474f003.webp`,
        },
        {
          name: "description",
          vmid: "description",
          content: `Download GBWhatsApp APK 2024 latest version. It's the BEST way to support OFFICIAL WhatsApp with extra features. Gb WhatsApp is 100% free & Anti-Ban.`,
        },
        {
          name: "og:title",
          vmid: "og:title",
          content: `GBWhatsApp APK Download  (Anti-Ban) ${this.$root.month} 2024 (Virus Free) | OFFICIAL`,
        },
        {
          name: "og:url",
          vmid: "og:url",
          content: window.location.href,
        },
        {
          name: "og:image",
          vmid: "og:image",
          content: `https://cdn.gbwhatsapp.download/img/icon_GB_new@2x.3474f003.webp`,
        },
        {
          name: "og:description",
          vmid: "og:description",
          content: `Download GBWhatsApp APK 2024 latest version. It's the BEST way to support OFFICIAL WhatsApp with extra features. Gb WhatsApp is 100% free & Anti-Ban.`,
        },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0",
        },
      ],
      // link: [
      // 	{
      // 		rel: "canonical",
      // 		vmid: "canonical",
      // 		href: `https://gbapksdownload.com`,
      // 	},
      // ],
    };
  },
};
</script>
